<template>
  <div id="download">
    <div class="download-header"></div>
    <div class="download-content">
      <div class="download-text">
        <div class="download-text-header">
          <img src="../../../static/img/dowload/mindLogo.png" alt="" />
          <span>寻简思维导图</span>
        </div>
        <div class="download-text-content">
          <p>
            您不仅可以在线使用Mind+，也可以下载手机版 给您一个更优雅的学习与工作空间 
          </p>
          <p>本地存储，云端同步，尽享完美思维梳理</p>
        </div>
        <div class="download-mind">
          <div class="download-mindMap">
            <img src="../../../static/img/dowload/mind.png" alt="" />
          </div>
        </div>
        <div class="download-entrance">
          <div class="download-entrance-center">
            <div class="download-code">
              <img src="../../../static/img/dowload/mindWeb.png" alt="" />
              <div>扫描二维码下载App</div>
            </div>
            <div class="download-entrance-class">
              <div class="download-android">
                <img src="../../../static/img/dowload/android.png" alt="" />
                <span
                  ><a href="http://www.mindyushu.com/" target="_self"
                    >Android版下载</a
                  ></span
                >
              </div>
              <div class="download-ipad">
                <img src="../../../static/img/dowload/ipad.png" alt="" />
                <span
                  ><a href="http://www.mindyushu.com/" target="_self"
                    >App Store下载</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  created(){
  }
}
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
#download {
  width: 100%;
  .download-header {
    height: 0rem;
  }
  .download-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    .download-text {
      padding: 2.9375rem;
      .download-text-header {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-size: 2.125rem;
        img {
          width: 3.75rem;
          height: 3.75rem;
        }
        span {
          margin-left: 0.9375rem;
        }
      }
      .download-text-content {
        letter-spacing: 0.1875rem;
        line-height: 2.1875rem;
        font-size: 1.3125rem;
        text-align: center;
        margin-top: 1.875rem;
        top: 8.55rem;
        margin: 0 auto;
        margin-top: 4rem;
      }
      .download-mind {
        margin-top: 5rem;
        display: flex;
        justify-content: center;
      }
      .download-entrance {
        margin-top: 4rem;
        .download-entrance-center {
          display: flex;
          justify-content: center;
          .download-code {
            position: relative;
            div {
              font-size: 1.3125rem;
              margin-top: 1.25rem;
            }
          }
          .download-entrance-class {
            padding: 0.5625rem 2.5rem;
            a {
              color: #666666;
            }
            .download-android {
              width: 14.4375rem;
              height: 3.8125rem;
              border: 0.0625rem solid #d6d6d6;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0.9375rem;
              span {
                margin-left: 1.25rem;
                font-size: 1.3125rem;
              }
            }
            .download-android:active {
              background-color: #ff7c65;
              transition: 0.5s;
              color: #ffffff;
              overflow: hidden;
              img {
                filter: drop-shadow(#ffffff 62px 0);
                transform: translateX(-62px);
              }
              a {
                color: #ffffff;
              }
            }
            .download-ipad {
              width: 14.4375rem;
              height: 3.8125rem;
              border: 1px solid #d6d6d6;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0.9375rem;
              span {
                margin-left: 1.25rem;
                font-size: 1.3125rem;
              }
            }
            .download-ipad:active {
              background-color: #ff7c65;
              transition: 0.5s;
              overflow: hidden;
              img {
                filter: drop-shadow(#ffffff 62px 0);
                transform: translateX(-62px);
              }
              a {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 250px) and (max-width: 450px) {
    .download-header {
      height: 0rem;
    }
    .download-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-color: #ffffff;
      .download-text {
        padding: 1.5625rem;
        .download-text-header {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333333;
          font-size: 1.5rem;
          img {
            width: 2.5rem;
            height: 2.5rem;
          }
          span {
            margin-left: 0.9375rem;
          }
        }
        .download-text-content {
          font-size: 0.875rem;
          text-align: center;
          margin-top: 1.875rem;
          top: 8.55rem;
          margin: 0 auto;
          margin-top: 1.875rem;
          letter-spacing: 0rem;
        }
        .download-mind {
          margin-top: 0rem;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
          }
        }
        .download-entrance {
          margin-top: 0rem;
          .download-entrance-center {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap-reverse;
            .download-code {
              margin-top: 1.25rem;
              img {
                width: 5.9375rem;
                height: 5.9375rem;
                margin-left: 0.925rem;
              }
              div {
                margin-top: 1.25rem;
                font-size: 0.875rem;
                width: 7.875rem;
              }
            }
            .download-entrance-class {
              a {
                color: #666666;
              }
              padding: 0.5625rem 0rem;
              display: flex;
              justify-content: center;
              flex-direction: row;
              .download-android {
                width: auto;
                min-width: 8.75rem;
                height: 3rem;
                border: 0.0625rem solid #d6d6d6;
                display: flex;
                align-items: center;
                margin: 0.2rem;
                span {
                  margin-left: 0.25rem;
                  font-size: 0.875rem;
                }
                img {
                  width: 1.1875rem;
                  height: 1.1875rem;
                }
              }
              .download-android:active {
                background-color: #ff7c65;
                transition: 0.5s;
                color: #ffffff;
                overflow: hidden;
                img {
                  filter: drop-shadow(#ffffff 62px 0);
                  transform: translateX(-62px);
                }
                a {
                  color: #ffffff;
                }
              }
              .download-ipad {
                width: auto;
                min-width: 8.75rem;
                height: 3rem;
                border: 1px solid #d6d6d6;
                display: flex;
                align-items: center;
                margin: 0.2rem;
                span {
                  margin-left: 0.25rem;
                  font-size: 0.875rem;
                }
                img {
                  width: 1.1875rem;
                  height: 1.1875rem;
                }
              }
              .download-ipad:active {
                background-color: #ff7c65;
                transition: 0.5s;
                color: #ffffff;
                overflow: hidden;
                img {
                  filter: drop-shadow(#ffffff 62px 0);
                  transform: translateX(-62px);
                }
                a {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    .download-header {
      height: 0rem;
    }
    .download-content {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-color: #ffffff;
      .download-text {
        padding: 1.5625rem;
        .download-text-header {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333333;
          font-size: 1.5rem;
          img {
            width: 2.5rem;
            height: 2.5rem;
          }
          span {
            margin-left: 0.9375rem;
          }
        }
        .download-text-content {
          font-size: 0.875rem;
          text-align: center;
          margin-top: 1.875rem;
          top: 8.55rem;
          margin: 0 auto;
          margin-top: 1.875rem;
          letter-spacing: 0rem;
        }
        .download-mind {
          margin-top: 0rem;
          position: absolute;
          left: 0rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .download-entrance {
          width: 50%;
          position: absolute;
          right: 0rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .download-entrance-center {
            display: flex;
            align-items: center;
            justify-content: center;
            .download-code {
              margin-top: 1.25rem;
              img {
                width: 10.78125rem;
                height: 10.78125rem;
                margin-left: 0rem;
              }
              div {
                margin-top: 1.25rem;
                font-size: 1.3125rem;
                width: 11.875rem;
              }
            }
            .download-entrance-class {
              a {
                color: #666666;
              }
              display: flex;
              justify-content: center;
              flex-wrap: wrap-reverse;
              align-items: center;
              max-width: 14.6875rem;
              margin: 0rem 1.5rem;
              height: 10.75rem;
              .download-android {
                width: auto;
                min-width: 14.5rem;
                height: 3.8125rem;
                border: 0.0625rem solid #d6d6d6;
                display: flex;
                align-items: center;
                transition: 0.5s;
                span {
                  margin-left: 1.375rem;
                  font-size: 1.3125rem;
                }
                img {
                  width: 1.9375rem;
                  height: 1.9375rem;
                }
              }
              .download-android:active {
                background-color: #ff7c65;
                transition: 0.5s;
                color: #ffffff;
                overflow: hidden;
                img {
                  filter: drop-shadow(#ffffff 62px 0);
                  transform: translateX(-62px);
                }
                a {
                  color: #ffffff;
                }
              }
              .download-ipad {
                width: auto;
                min-width: 14.5rem;
                height: 3.8125rem;
                border: 0.0625rem solid #d6d6d6;
                display: flex;
                align-items: center;
                span {
                  margin-left: 1.375rem;
                  font-size: 1.3125rem;
                }
                img {
                  width: 1.9375rem;
                  height: 1.9375rem;
                }
              }
              .download-ipad:active {
                background-color: #ff7c65;
                transition: 0.5s;
                color: #ffffff;
                overflow: hidden;
                img {
                  filter: drop-shadow(#ffffff 62px 0);
                  transform: translateX(-62px);
                }
                a {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>